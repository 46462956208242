
import Container from '@mui/material/Container';

import SocialButtons from '../common/social-buttons';

export default function Footer() {

  return (
    <Container sx={{ py: 4 }}>
      <SocialButtons />
    </Container>
  );

}
