import 'src/global.css';

// ----------------------------------------------------------------------

import ThemeProvider from 'src/theme';
import Router from 'src/routes/sections';
import { SettingsProvider } from 'src/components/settings';
import MotionLazy from 'src/components/animate/motion-lazy';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'dark', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeColorPresets: 'preset02', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
      }}
    >
      <ThemeProvider>
        <MotionLazy>
          <Router />
        </MotionLazy>
      </ThemeProvider>
    </SettingsProvider>
  );
}
