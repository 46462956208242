// @mui
import { Stack, IconButton } from '@mui/material';

import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function SocialButtons() {

  return (
    <Stack direction="row" justifyContent="center" paddingBottom={1}>
      <IconButton
        target="_blank"
        href="https://twitter.com/StakeBin"
      >
        <Iconify
            width={32}
            icon='ri:twitter-x-fill'
        />
      </IconButton>
      <IconButton
        target="_blank"
        href="https://discord.gg/KTKc33PRev"
      >
        <Iconify
            width={32}
            icon='ic:round-discord'
        />
      </IconButton>
      <IconButton
        target="_blank"
        href="https://t.me/StakeBin"
      >
        <Iconify
            width={32}
            icon='icon-park-outline:telegram'
        />
      </IconButton>
      <IconButton
        target="_blank"
        href="mailto:contact@stakebin.io"
      >
        <Iconify
            width={32}
            icon='ic:round-email'
        />
      </IconButton>
    </Stack>
  );
}
