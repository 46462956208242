import { memo } from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import Image from '../image/image';

// ----------------------------------------------------------------------

function Logo({ single = false, sx }) {

  const singleLogo = (
    <Image
      src="/assets/icons/stakebin.png"
      alt="logo"
      sx={{ width: 48, height: 48 }}
    />
  );

  const fullLogo = (
    <Stack direction="row" alignItems="center" >
      <Image
        src="/assets/icons/stakebin.png"
        alt="logo"
        sx={{ width: 40, height: 40 }}
      />
      <Box
        sx={{
          ml: 2,
          display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}
      >
        <Typography variant="h4" sx={{ color: 'text.primary', textAlign: 'center' }}>
          StakeBin
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <Link
      component={RouterLink}
      href="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 64 : 75,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

Logo.propTypes = {
  single: PropTypes.bool,
  sx: PropTypes.object,
};

export default memo(Logo);
