import IconButton from '@mui/material/IconButton';

import Iconify from '../../iconify';

import { useSettingsContext } from './settings-context';

export default function DarkModeToggle() {
    const settings = useSettingsContext();

    return (
        <IconButton
            onClick={() =>
                settings.onUpdate('themeMode',
                settings.themeMode === 'dark' ? 'light' : 'dark')
            }
        >
            <Iconify
                width={26}
                icon={settings.themeMode === 'dark' ? 'carbon:asleep-filled' : 'carbon:asleep'}
            />
        </IconButton>
    );
}